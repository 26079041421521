import { ErrorHandler, Injectable } from '@angular/core';
import { LogDTO } from '../DTO/LogDTO';
import { OfflineStorageElement, OfflineStorageService } from './offline-storage.service';
import { SyncService } from './sync.service';
import * as localforage from 'localforage';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandlerService extends ErrorHandler {

  constructor(private offlineStorageService: OfflineStorageService) {
      super();
  }

  async handleError(error) {
      // Here you can provide whatever logging you want
      localforage.getItem('User').then(async (responseData: any) => {
        const log: LogDTO = {
          uuid: v4(),
          message: error.stack,
          userName: responseData?.username,
          userId: responseData?.id,
          companyName: responseData?.company?.name,
          companyId: responseData?.company?.id,
          date: new Date(),
          type: 'app'
        };

        try {
          // TEMP disable
          // await this.offlineStorageService.push(OfflineStorageElement.Logs, log).then((res) => {});
        } catch (err) {
          
        }
      });
      super.handleError(error);
  }
}