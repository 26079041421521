import {TokenService} from '../token.service';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function jwtOptionsFactory(tokenService: TokenService) {
  return {
    headerName: 'Authorization',
    authScheme: 'Bearer ',
    throwNoTokenError: false,
    allowedDomains: environment.allowedDomains,
    disallowedRoutes: environment.disallowedRoutes,
    tokenGetter: () => tokenService.getToken()
  };
}
