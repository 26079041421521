import { environment } from 'src/environments/environment';
import {Component, OnInit} from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from './shared/services/auth/token.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OfflineStorageService } from './shared/services/offline-storage.service';
import { Fancybox } from '@fancyapps/ui';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  public env = environment;
  event: any;
  user: any;

  constructor(
    private translate: TranslateService,
    public actionSheetController: ActionSheetController,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private offlineStorage: OfflineStorageService
  ) {
    this.setAppLanguage();
    Fancybox.bind('[data-fancybox]', {
      // Options
    });
  }

  setAppLanguage() {
    this.translate.setDefaultLang('es');
    let languageString = 'es';
    if (localStorage.getItem('language')) {
      languageString = localStorage.getItem('language');
    } else {
      localStorage.setItem('language', languageString);
    }
    this.translate.use('es');
  }

  useLanguage(language: string): void {
    this.translate.use(language);
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      mode: 'ios',
      header: this.translate.instant('idiomas'),
      buttons: [{
        text: 'Castellano',
        handler: () => {
          this.useLanguage('es');
          localStorage.setItem('language', 'es');
        }
      }, {
        text: 'Català',
        handler: () => {
          this.useLanguage('ca');
          localStorage.setItem('language', 'ca');
        }
      },
      {
        text: 'English',
        handler: () => {
          this.useLanguage('en');
          localStorage.setItem('language', 'en');
        }
      }]
    });
    await actionSheet.present();

  }


  logOut() {
    this.tokenService.removeTokens();
    this.router.navigate(['login']);
  }

  ngOnInit(): void {
    this.user = '';
    this.offlineStorage.getUserObs().subscribe(res => {
      if(res){
        this.user = res.username;
      } else {
        this.user = '';
      }
    });
  }
}
