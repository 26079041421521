import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OfflineStorageElement, OfflineStorageService } from '../services/offline-storage.service';
import * as localforage from 'localforage';
import { LogDTO } from '../DTO/LogDTO';
import { v4 } from 'uuid';

@Injectable({
    providedIn: 'root'
  })
  export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private offlineStorageService: OfflineStorageService) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler) {
      return next.handle(req).pipe(
        catchError((error, caught) => {
          
          if (error.status === 401){
            return throwError(error);
          }

          let errorMessage = '';
          let type: string;
          if (error instanceof ErrorEvent) {
            // client-side error
            type = 'client';
            errorMessage = `Client-side error: ${error.error.message}`;
          } else {
            // backend error
            type = 'http';
            errorMessage = `Server-side error: ${error.status} ${error.message}`;
          }

          // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
          localforage.getItem('User')
          .then(async (responseData: any) => {
            const log: LogDTO = {
              uuid: v4(),
              message: errorMessage,
              body: req.body ? JSON.stringify(req.body) : null,
              userName: responseData?.username,
              userId: responseData?.id,
              companyName: responseData?.company?.name,
              companyId: responseData?.company?.id,
              date: new Date(),
              type
            };
  
            try {
              // TEMP disable
              // await this.offlineStorageService.push(OfflineStorageElement.Logs, log).then((res) => {});
            } catch (err) {
              
            }
          });

          return throwError(error);
        })
      );
    }
  }
