import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './shared/services/auth/authenticated.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'proyectos',
    redirectTo: 'projects',
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/home-proyectos/proyectos.module').then( m => m.ProyectosPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuid',
    loadChildren: () => import('./pages/proyecto-detail/proyecto.module').then( m => m.ProyectoPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuid/registers',
    loadChildren: () => import('./pages/registers-list/registros.module').then( m => m.RegistrosPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuidProyecto/registers/:uuidCala',
    loadChildren: () => import('./pages/register-detail/cala.module').then( m => m.CalaPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuidProyecto/registers/:uuidCala/template',
    redirectTo: 'projects/:uuidProyecto/registers/:uuidCala/preview',
  },
  {
    path: 'projects/:uuidProyecto/registers/:uuidCala/preview',
    loadChildren: () => import('./pages/plantillas/dynamic-template/dynamic-template.module').then( m => m.DynamicTemplatePageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuidProyecto/registers/:uuidCala/preview/editor',
    loadChildren: () => import('./pages/plantillas/dynamic-template/editor/editor.module').then( m => m.EditorPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuidProyecto/registers/:uuidCala/unidades',
    redirectTo: 'projects/:uuidProyecto/registers/:uuidCala/units'
  },
  {
    path: 'projects/:uuidProyecto/registers/:uuidCala/units/new',
    loadChildren: () => import('./pages/unidad/unidad.module').then( m => m.UnidadPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuidProyecto/registers/:uuidCala/units/:uuidUnidad',
    loadChildren: () => import('./pages/unidad/unidad.module').then( m => m.UnidadPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'projects/:uuidProyecto/trucks',
    loadChildren: () => import('./pages/trucks/trucks.module').then( m => m.TrucksPageModule)
  },
  {
    path: 'projects/:uuidProyecto/trucks/truck',
    loadChildren: () => import('./pages/trucks/truck-form/truck-form.module').then( m => m.TruckFormPageModule)
  },
  {
    path: 'projects/:uuidProyecto/trucks/truck/:uuidTruck',
    loadChildren: () => import('./pages/trucks/truck-form/truck-form.module').then( m => m.TruckFormPageModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pages/configuration/configuration.module').then( m => m.ConfigurationPageModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/statistics/statistics.module').then( m => m.StatisticsPageModule)
  },
  {
    path: 'register-packs',
    loadChildren: () => import('./pages/quota-register-packs/register-packs.module').then( m => m.RegisterPacksPageModule)
  },
  {
    path: 'file-converter/:type',
    loadChildren: () => import('./pages/GAIA_MODULES/file-converter/file-converter.module').then( m => m.FileConverterPageModule)
  },
  {
    path: 'tools',
    loadChildren: () => import('./pages/GAIA_MODULES/tools.page.module').then( m => m.ToolsPageModule)
  },
  {
    path: 'recoverPassword',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    path: 'resetPassword',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'offline-projects',
    loadChildren: () => import('./pages/offline-projects/offline-projects.module').then( m => m.OfflineProjectsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
