import { Injectable } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {TokenData} from './resources/token-data';

const helper = new JwtHelperService();

@Injectable()
export class TokenService {

  constructor(
  ) { }

  getToken(): string {
    return localStorage.getItem('access_token');
  }

  setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refresh_token', token);
  }

  getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  removeTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  isValid() {
    if (!this.getTokenData()) {
      return false;
    }
    //return !this.isExpired();
    return true; // for work offline
  }
  
  isExpired(): boolean {
    return helper.isTokenExpired(this.getToken());
  }

  getTokenData(): TokenData {
    try {
      return helper.decodeToken(this.getToken());
    } catch (error) {
      return null;
    }
  }

  getRoles(): string {
    return this.getTokenData().role;
  }

  getUserName(): string {
    return this.getTokenData().unique_name;
  }

  getUserId(): string {
    return this.getTokenData().user_id;
  }

}

